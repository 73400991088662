<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <div class="col-md-6 d-flex align-items-stretch mb-3">
            <div class="row flex-grow">
                <div class="col-12 p-0">
                    <div class="text-left">
                        <b-btn v-on:click="addSpotter" variant="primary">
                            {{ $t('add-spotter-btn') }}
                        </b-btn>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 stretch-card">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{ $t('spotters') }}</h4>
                    </div>
                    <div class="card-body new-card-body">
                        <vue-good-table
                                mode="remote"
                                @on-page-change="onPageChange"
                                @on-sort-change="onSortChange"
                                @on-column-filter="onColumnFilter"
                                @on-per-page-change="onPerPageChange"
                                :totalRows="totalRecords"
                                :isLoading.sync="isLoading"
                                :pagination-options="{enabled: true,dropdownAllowAll: false,perPageDropdown: [10, 20, 50, 100, 200],}"
                                :rows="rows"
                                :columns="columns"
                                @on-search="searchFn"
                                :search-options="{enabled: true,searchFn: searchFn}"
                                :row-style-class="checkDeleted"
                                :line-numbers="true">
                            <template slot="table-row" slot-scope="props">
                                                             <span
                                                                     v-if="props.column.field === 'action'"
                                                                     class="text-nowrap"
                                                             >
                                                                 <b-button size="sm" v-if="props.row.deleted_at === null" @click="editSpotter(props.row.id)"
                                                                           class="mr-1 btn-info text-white">
                                                                {{ $t('view-profile-btn') }}
                                                            </b-button>
                                                            <b-button size="sm" v-if="props.row.deleted_at === null" @click="deleteSpotter(props.row.id)"
                                                                      class="mr-1 btn-danger text-white">
                                                                {{ $t('delete-btn') }}
                                                            </b-button>
                                                            <b-button size="sm" v-if="props.row.deleted_at != null"
                                                                      class="mr-1 btn-disabled text-white btn-deleted">
                                                                {{ $t('deleted-btn') }}
                                                            </b-button>
                                                            <b-button size="sm" v-if="props.row.deleted_at != null"
                                                                      class="mr-1 btn-disabled text-white btn-restore" @click="restoreSpotter(props.row.id)">
                                                                {{ $t('restore-btn') }}
                                                            </b-button>
                                                            <!-- <b-button size="sm"
                                                                      class="mr-1 btn-disabled text-white btn-deleted" @click="permanentDeleteSpotter(props.row.id)">
                                                                Permanent del
                                                            </b-button> -->
                                                             </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </section>
</template>

<script>
    import Vue from "vue";
    import SortedTablePlugin from "vue-sorted-table";
    import API from '@/api'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import {validationMixin} from "vuelidate";
    import {required, minLength, email, requiredIf, sameAs} from "vuelidate/lib/validators";
    import moment from 'moment'

    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    Vue.use(SortedTablePlugin, {
        ascIcon: '<i class="ti-arrow-down"></i>',
        descIcon: '<i class="ti-arrow-up"></i>'
    });
    export default {
        name: 'Spotters',
        mixins: [validationMixin],
        data() {
            return {
                isDisabled: false,
                serverParams: {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    // a map of column filters example: {name: 'john', age: '20'}
                    columnFilters: {},
                    sort: [
                        {
                            field: 'id', // example: 'name'
                            type: 'desc' // 'asc' or 'desc'
                        }
                    ],

                    page: 1, // what page I want to show
                    perPage: 10 // how many items I'm showing per page
                },
                columns: [
                    {
                        label: 'Username',
                        field: 'user_name',
                        filterable: true
                    },
                    {
                        label: 'Name',
                        field: 'name',
                        filterable: true
                    },
                    {
                        label: 'Phone',
                        field: 'mobile_phone',
                        sortable: true,
                    },
                    {
                        label: 'Training Status ',
                        field: "training_status",
                        sortable: true,
                        formatFn: this.trainingStatus,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: '-',
                            filterValue: '',
                            filterDropdownItems: [
                                { value: '0', text: 'No' },
                                { value: '1', text: 'Yes' },
                            ],
                            filterFn: this.columnFilterFn,
                            trigger: 'enter',
                        },
                    },
                    {
                        label: 'Auto Approve Spots',
                        field: "auto_approve",
                        sortable: true,
                        formatFn: this.formatStatus,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: '-',
                            filterValue: '',
                            filterDropdownItems: [
                                { value: '0', text: 'No' },
                                { value: '1', text: 'Yes' },
                            ],
                            filterFn: this.columnFilterFn,
                            trigger: 'enter',
                        },
                    },
                    {
                        label: 'Suspended',
                        field: "status",
                        sortable: true,
                        formatFn: this.status,
                        filterOptions: {
                            styleClass: 'class1',
                            enabled: true,
                            placeholder: '-',
                            filterValue: '',
                            filterDropdownItems: [
                                { value: 'I', text: 'No' },
                                { value: 'A', text: 'Yes' },
                            ],
                            filterFn: this.columnFilterFn,
                            trigger: 'enter',
                        },
                    },
                    {
                        label: 'Action',
                        field: "action",
                        sortable: false,
                    },
                ],
                isLoading: false,
                rows: [],
                totalRecords: 0,
            };
        },
        methods: {
            deleteSpotter(e) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "All the spots against this spotter will be deleted. You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        let data = {
                            id: e
                        }
                        API.deleteSpotter(
                            e,
                            data => {
                                this.loadItems();
                                this.$swal(
                                    'Deleted!',
                                    'Record has been deleted.',
                                    'success'
                                )
                            },
                            err => {
                            })
                    }
                })
            },
            restoreSpotter(e) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You want to restore Spotter!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Restore it!'
                }).then((result) => {
                    if (result.value) {
                        let data = {
                            id: e
                        }
                        API.restoreSpotter(
                            e,
                            data => {
                                this.loadItems();
                                this.$swal(
                                    'Restore!',
                                    'Record has been restored.',
                                    'success'
                                )
                            },
                            err => {
                            })
                    }
                })
            },
            permanentDeleteSpotter(e) {
                let data = {
                    id: e
                }
                API.permanentDeleteSpotter(
                    e,
                    data => {
                        this.loadItems();
                        this.$snotify.success(data.message);
                    },
                    err => {
                    })
            },
            addSpotter() {
                this.$router.push({name: 'addSpotter'});
            },
            editSpotter(e) {
                this.$router.push({name: 'editSpotter', params: {id: e}});
            },
            loadItems() {
                this.isLoading = true;
                API.getAllClientSpotters(this.serverParams,
                    data => {
                        this.isLoading = false;
                        this.totalRecords = data.totalRecords;
                        this.rows = data.data.data;
                    },
                    err => {
                    }
                )
            },
            formatDate(value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD')
                }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            searchFn(params) {
                console.log('okkok')
                this.updateParams({searchTerm: params});
                this.loadItems();
                this.isLoading = false;
            },
            formatStatus(status) {
                if (status === 1) {
                    return 'Yes';
                } else {
                    return 'No';
                }
            },
            trainingStatus(training_status) {
                if (training_status == 1) {
                    return 'Yes';
                } else {
                    return 'No';
                }
            },
            status(status) {
                if (status === 'A') {
                    return 'Yes';
                } else {
                    return 'No';
                }
            },
            checkDeleted(row) {
                console.log(row.deleted_at);
                return row.deleted_at !== null ? 'red-text' : '';
            },
        }, computed: {},
        mounted() {
            this.isLoading = true;
            this.loadItems();
        }
    }
</script>

<style lang="scss">
    .red-text .vgt-left-align {
        color: red !important;
    }
    .btn-disabled {
        width: 100px;
    }
    .btn-restore {
        width: 70px !important;
        padding-left: 10px !important;
        background: #5D9E12;
        border-color: #5D9E12;
    }
    @media (max-width: 1150px) {
        .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
</style>

